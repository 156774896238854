.editeur {
    margin-bottom: 30px;
    width: 100%;

    .error {
        border-color: red;
    }

    .toolbar {
        border-radius: 6px 6px 0px 0px;
        background: rgba(100, 73, 150, 0.10);
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        padding: 0 10px;
        position: relative;

        .editorActionsLeft {
            display: flex;

            button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 16px;
                color: #2B272D;
                transition: color 0.2s;
                border-right:1px solid black;
                padding: 0 15px;

                &:hover {
                    color: #c34177;
                }
            }

            button:last-child {
                border-right: none;
            }

            .emojiButton {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .editorActionsRight {
            display: flex;

            .chatGptButton {
                display: flex;
                align-items: center;
                justify-content: center;
                
            }

            .iconToolbar {
                margin-right:5px;
            }

            button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 16px;
                color: #2B272D;
                transition: color 0.2s;
                margin: 0 15px;

                span {
                    font-weight: bold;
                    margin-left:10px;
                }

                &:hover {
                    color: #c34177;
                }
            }

            p {
                color: #908A93;
                font-size: 16px;
                margin-left: 15px;
            }

        }

        // Varibable
        .dropdownVar {
            position: absolute;
            left: 145px;
            top: 40px;
            z-index: 1000;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px 20px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

            div {
                cursor: pointer;
                padding: 5px 0;
                transition: background-color 0.2s;

                &:hover {
                    color:#c34177;
                }
            }
        }
    }

    .contentEditable {
        width: 100%;
        min-height: 200px;
        padding: 10px;
        border-radius: 0px 0px 6px 6px;
        border: 1px solid rgba(49, 16, 119, 0.15);
        background: #FFF;
        resize: vertical;

        &:focus-visible {
            outline: none;
        }
    }

    // Emoji
    .emojiPicker {
        position: absolute;
        top: 50px;
        left: 80px;
        z-index: 1000;
    }
    
    .emojiButton {
        background: none !important;
        border: none !important;
        cursor: pointer !important;
        font-size: 18px !important;
        margin-top: -4px !important;
        margin-left: -2px !important;
    }
}