.buttonBase {
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
    text-decoration: none;
}

.full {
    background: var(--Dgrad);
    border: none;
    color: white;
}

.outlined {
    border: 1.5px solid var(--basecolor);
    background: var(--Dgrad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.outlined:hover {
    background: var(--Dgrad);
    -webkit-text-fill-color: white;
}

.textOnly {
    border: none;
    text-decoration: none;
    background: var(--Dgrad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.degradPurple {
    --basecolor: var(--darkPurple);
    --Dgrad: var(--degradPurple);
}

.purple {
    --basecolor: var(--purple);
    --Dgrad: linear-gradient(116deg, var(--purple) 14.41%, var(--purple) 97.94%);
}

.red {
    --basecolor: var(--redWhakup);
    --Dgrad: linear-gradient(116deg, var(--redWhakup) 14.41%, var(--redWhakup) 97.94%);
}