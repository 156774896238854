.carrousel {
    width: 100%;

    .carrousel-info {
        
    }

    .error {
        color: red;
    }

    .carrousel-container {
        margin-top:30px;
        width: 100%;
        height: auto;
        background-color: #f3f2f7;

        .carrousel-item {
            padding-bottom:1px;

            .item {
                height:80px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
            }
            
            .handle {
                margin-left:15px;
                margin-right:15px;
                font-size: 20px;
                cursor: pointer;
            }

            .media {
                max-height: 65px;
                max-width: 65px;

                .img {
                    width: 100%;
                    height: 100%;
                    max-height: 65px;
                    max-width: 65px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }

            .carrousel-item-content {
                margin-left:15px;
                width: calc(100% - 80px);
                text-overflow: ellipsis;

                .message {
                    max-height:65px;
                    overflow: hidden;
                }
                
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 300;
                    color: #000;
                }
            }

            .actions {
                margin-left: auto;
                margin-right: 15px;
                display: flex;
                align-items: center;
                justify-content: start;
                cursor: pointer;
                font-size:20px;

                svg {
                    margin-right:15px;
                }
            }

        }

        .collapse-content {
            background-color:white;
            margin: 15px;
            padding: 15px;
            border-radius: 8px;

            .collapse-media {
                width: 100%;
                display: flex;
                gap: 10px;

                .default-image {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 8px;
                }

                .image-preview {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 8px;
                }

                .editeur {
                    width: 100%;
                }

            }
        }

        .editeur {
            margin-left:15px;
            margin-bottom:0px !important;
        }

        // .quill {
        //     width: 100%;
        //     min-height: 100px !important;

        //     .ql-container {
        //         height: 100%;
        //         min-height: 100px;
        //     }
            
        //     .ql-editor {
        //         min-height: 80px;
        //     }
        // }
    }

    .btn-add {
        margin-top:30px;

        button {
            border-radius: 50px;
            border: 1px solid rgba(189, 45, 105, 0.90);
            background: rgba(254, 254, 254, 0.20);
            padding: 10px 20px;
            color: black;
            font-size: 14px;
            font-weight: 500;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                background-color: var(--primary);
                color: #fff;
            }
        }
    }


}