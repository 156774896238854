.list-member {

    .member-header {
        display: flex;
        justify-content: space-between;

        button {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            background-color: #007bff;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }

            svg {
                margin-right: 5px;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

        thead {
            background-color: #f5f5f5;
            color: #333;
            font-weight: 600;
            text-align: left;
            border-bottom: 1px solid #e0e0e0;

            th {
                padding: 10px 15px;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #e0e0e0;

                td {
                    padding: 10px 15px;
                    vertical-align: middle;
                    font-size: 14px;
                    color: #333;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }


}