.baseNotif {
    font-family: Poppins;
    font-style: normal;
    display: flex;
    width: 100%;
    padding: 20px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-radius: 16px;
    background: var(--white);
}

.baseNumber {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    gap: 16px;
}

.baseIconLeft {
    font-size: x-large;
    display: inline-block;
}

.mainContentNotif {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.contentNotif {
    display: inline;
    color: var(--textColor);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.rightContent {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}

.baseIconRight {
    font-size: xx-large !important;
    display: inline-block;
}

.arrowNotif {
    color: var(--textColor);
}

.redNotif {
    color: var(--red);
}

.greenNotif {
    color: var(--green);
}

.greyNotif {
    color: var(--darkGrey);
}