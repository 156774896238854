.subscription {

    .subscription-content {
        margin-top:30px;
        background-color: white;
        border-radius: 16px;
        padding: 24px 36px;

        .myPlan {
    
            .myPlanHeader {
                display: flex;
    
                h2 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                    text-align: left;
                    margin-right: 20px;
                }
    
                span {
                    font-size: 13px;
                    line-height: 25px;
                    text-align: left;
                    color: white;
                    background-color: green;
                    border-radius: 30px;
                    padding: 0 20px;
                    height: 25px;
                    margin-top: 5px;
                }

                .free {
                    background-color:red;
                }
    
                .btn {
                    margin-left: auto;
                    border:1px solid var(--violet);
                    background-color: white;
                    border-radius: 30px;
                    padding: 0 20px;
                    height: 25px;
                    margin-top: 5px;
                    font-size: 13px;
                    line-height: 25px;
                    text-align: left;
                    color: var(--violet);
                    transition:0.3s;
                    cursor: pointer;
    
                    &:hover {
                        background-color: var(--violet);
                        color: white;
                        transition:0.3s;
                    }
                }
            }
        }
    
    }


}