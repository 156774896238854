.template-header {

}

.template-body {
    margin-top:10px;
}

.template-footer {
    margin-top:10px;
    p {
        font-size: 12px;
    }
}

.template-button {
    margin-top:15px;
    button  {
        border:none;
        padding: 5px 15px;
        border-radius: 5px;
        background: #ffffff;
        //cursor: pointer;
        width: 100%;
        text-align: center;
        font-size: 14px;
        word-break: break-all;
        margin-bottom: 5px;
        font-weight: 500;
        text-transform: uppercase;

        &:first-child {
            margin-right: 5px;
        }

        svg {
            width: 12px;
            margin-right: 5px;
        }

        a {
            color: #000;
            text-decoration: none;
        }
    }
}