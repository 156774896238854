.tuto {
    display: flex;
    flex-direction: row;
    margin-top:30px;
    margin-bottom:30px;
    flex-flow: row wrap;

    a {
        text-decoration: none;
        color:black;
    }

    .meta-tuto {
        display: flex;
        border-radius: 50px;
        border: 1.5px solid var(--rouge-whakup, #E41919);
        height: 50px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .meta-icon {
            background-image:url("../../assets/images/icons/icon-meta.png");
            background-repeat: no-repeat;
            background-size: contain;
            height: 24px;
            width: 28px;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            align-items: center;
            line-height: 36px;
        }

        .icon-red {
            background-color: #E41919;
            height: 16px;
            width: 16px;
            border-radius: 50%;
        }
    }


}