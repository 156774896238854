.popup {
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
    width: 100vw; height: 100vh;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn .15s;
    background-color: rgba(0, 0, 0, .5);

    &.top {
        align-items: flex-start;
        padding-top: 2.5vh;
    }

    .popup-container {
        max-width: 90vw; min-width: 600px;
        max-height: 95vh;
        animation: slideDownFade .45s;
        background-color: white;
        position: relative;
        overflow: auto;
        border-radius: 16px;

        @media screen and (max-width: 1024px) {
            min-width: 90vw;
        }  
        
        h2 {
            font-weight: 500;
            font-size: 20px;
            color:#2B272D;
        }

        h4 {
            padding: 1rem;
            padding-right: 3rem;
            border-bottom: 1px solid #dee2e6;
            font-weight: 500;
            font-size: 18px;
        }

        .popup-content {
            padding: 1rem;
            line-height: 1.5;
        }

        .closePopupIcon {
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
            padding: 1rem;
            opacity: .5;
            z-index: 200;

            img {
                $size: 10px;
                width: $size;
                height: $size;
            }

            &:hover { opacity: .75 }
        }
    }

    &.closed {
        opacity: 0;
        transition: opacity .15s;
        transition-delay: .3s;

        .popup-container {
            opacity: 0;
            transform: translateY(-50px);
            transition: all .3s;
        }
    }

    // OPEN
    @keyframes fadeIn {
        from { opacity: 0 }
        to { opacity: 1 }
    }

    @keyframes slideDownFade {
        0% { opacity: 0; transform: translateY(-50px); }
        33% { opacity: 0; transform: translateY(-50px); }
        100% { opacity: 1; transform: translateY(0px); }
    }


    // CUSTOM CONTENT

    .popupCreateHorseQuick {
        padding: 1rem;
        margin-top: 0;

        > * + *:not(.MuiPopper-root) { margin-top: 1.5rem }

        .btn-container {
            display: flex;
            gap: 15px;

            .cancel {
                color:white;
                background-color: rgb(245, 81, 81);
            }

            .cancel:hover {
                text-decoration: underline;
            }

            button {
                //width: 100px;
            }
        }
    }

    .upgradePlan {
        .btn-add {
            margin-top:30px;
            background-color: var(--primary);
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            text-decoration: none;
            max-width: 220px;
            text-align: center;
            display: block;
        }
    }

    .popupExportDate {
        padding-top: 2.5rem;
    }

    .popupContentAfterSubmit {
        padding: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .chatGptResult {

            h3 {
                margin-bottom: 1rem;
            }

            .chatGptResultContent {
                padding: 1rem;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                width: 700px;
                max-height: 300px;
                overflow: auto;
                display: flex;
                flex-direction: row;

                p {
                    margin: 0;
                    text-align: left;
                }

                svg {
                    width: 15px;
                    height: 15px;
                    margin-left: 1rem;
                    cursor: pointer;
                }
                
            }

        }

        .btn-container {
            margin-top: 1rem;
            button {
                margin-left: 15px;
            }
        }
    }

    .chatGptPopup {    
        .form-group {
            display: flex;
            flex-direction: row;

            textarea {
                width: 100%;
                padding: 1rem;
                border-radius: 5px;
                border: 1px solid #dee2e6;
                resize: none;
                height: 150px;
                font-size: 16px;
            }
            
        }

        .btn-container {
            margin-top:30px;
        }
        
    }


}

.content-popup-campaign {

    .info-campaign {
        border-radius: 6px;
        background: rgba(100, 73, 150, 0.05);
        padding: 1rem;
        margin-bottom: 1rem;

        p {
            color: rgba(43, 39, 45, 0.80);
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
        }
    }
}