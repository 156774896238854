.kpi-graph-line {
    width: 65%;
    height: 400px;
    margin-top: 25px;
    margin-bottom: 65px;

    .title {
        margin-bottom: 30px;

        h3 {
            color: #2B272D;
            font-size: 24px;
            font-weight: 600;
        }
    }
}