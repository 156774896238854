.workspace-container {
    margin:25px 10px 0 10px;

    .active {
        //background-color: var(--primary);
        
    }

    .workspace {
        display: flex;
        align-items: center;
        padding: 8px 30px;
        cursor: pointer;
        transition: 0.1s;
        border-radius: 50px;
        border: 1px solid rgba(115, 30, 112, 0.15);
        width: 85%;
        margin:0 auto;
        display: flex;
        justify-content: space-between;

        p {
            color: rgba(115, 30, 112, 0.90);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        svg {
            font-size:12px;
            ;
        }

    }

    .list-workspace {
        position: absolute;
        z-index: 300;
        background-color: white;
        width: 310px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 5px;
        padding: 10px;
        left: 20px;
        max-height:60vh;
        overflow: auto;

        .one-workspace {
            cursor: pointer;
            p {
                font-weight: 300;
                font-size: 16px;
                color: #333;
                margin: 10px 0;
            }

            &:hover {
                background-color: #f9f9f9;
            }
        }

        .add-workspace {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
                font-weight: 600;
            }
            
            svg {
                font-size: 15px;
                color: black;
                margin-right: 10px;
                border-radius:40px;
                border:1px solid #e0e0e0;
                background-color: white;
                padding: 10px;
            }
        }

    }

    .btn-submit {
        margin-top:15px;
    }

}
