nav {
    width: 80px;
    height: 100vh;
    background-color: white;
    position: fixed;
    z-index: 50;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #F2F2F3;
    box-shadow: 0px 0px 8.1px 0px rgba(138, 168, 223, 0.50);

    .logo-container {
        width: 100%;
        height: 60px;

        .logo {
            width: 80px;
            height: 24px;
            margin:25px 0 0 0;
            background-image: url('../../assets/images/logo-min.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .workspace-container {
        .workspace {
            padding: 8px 10px;
            justify-content: center;

            p::first-letter {
                display: inline; /* Change le comportement de bloc */
                font-size: inherit; /* Hérite de la taille de la police */
            }
    
            p {
                color: transparent; /* Cache tout le texte */
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                justify-content: center;
            }
    
            p::first-letter {
                color: #311077;
                font-family: Poppins;
            }

            svg { 
                display: none;
            }
        }
    }

    .main-nav {
        padding:40px 0 0 0;
        width: 90%;

        ul {

            a {
                text-decoration: none;
                color: #757784;
                display: block;
                margin: 0 0 1em 0;
                padding: 10px 10px 10px 25px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;

                li {
                    display: flex;

                    span {
                        font-size: 14px;
                        font-weight: 400;
                        width: 100%;
                        display: none;
                        &.badge {
                            margin-right: 10px;
                            text-align: right;
                        }
                    }

                    img {
                        font-size: 16px;
                        margin-right: 10px;
                        color:#757784;
                    }

                    .restricted {
                        float:right;
                        font-size: 12px;
                        margin-top:6px;
                        color: #364152;
                        display: none;
                    }

                }

                &:hover {
                    background: rgba(55, 26, 117, 0.10);
                    color: #371A75;
                    font-weight: 500;

                    img {
                        color: #75251a;
                    }

                    .restricted {
                        color: #364152;
                    }
                }
            }
        }

        .active {
            background: rgba(55, 26, 117, 0.10);
            color: #371A75;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }
    
    }

    .settings-nav {
        position: absolute;
        bottom: 50px;

    }

    &:hover {
        width: 250px;

        .main-nav {
            ul {
                a {
                    span {
                        display: block;
                    }

                    .restricted {
                        display: block;
                    }
                }
                
            }

            .active {
                background: rgba(55, 26, 117, 0.10);
                color: #371A75;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                font-weight: 500;
        
                img {
                    color: #371A75;
                }
        
                .restricted {
                    color: #364152;
                }
            }
        }

        .logo-container {
            width: 100%;
            height: 60px;
    
            .logo {
                width: 190px;
                height: 24px;
                margin:25px 0 0 0px;
                background-image: url('../../assets/images/logo.png');
                background-size: cover;
            }
        }

        .workspace-container {
            .workspace {
                padding: 8px 18px;
                justify-content: space-between;
        
                p {
                    color: #311077;
                    font-family: Poppins;
                    justify-content: center;
                }
        
                svg { 
                    display: block;
                }
            }
        }

    }
    
}

.isTrial {
    top:30px;
}