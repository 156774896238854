.campaign {
    .background-white-title {
        display: flex;
        justify-content: space-between;

        h1 {
            color: var(--textColor);
            font-family: Poppins;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .actions {
            display: flex;
            align-items: center;
        }
    }
}

.create-campaign {
    display: flex;
    flex-direction: row;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    line-height: normal !important;
    width: 1324px;

    .container {
        margin-right: 56px;
        width: 733px;

        .create-campaign-container {
            margin-right: 80px;
            margin-bottom: 24px;
            background: var(--createCampaignContainerColor);
            border-radius: 24px;

            .title-page {
                margin-bottom: 36px;

                h1 {
                    color: var(--whiteText);
                    font-size: 24px;
                    font-weight: 500;
                    border-radius: 24px 24px 6px 6px;
                    padding: 12px 36px;
                    background: var(--degradPurple);
                }

                p {
                    color: var(--greyText);
                    font-size: 16px;
                    font-weight: 500;
                    padding: 16px 36px 0px 36px;
                }
            }

            form {
                margin: 0px 36px;

                .form-group {
                    .MuiFormControl-root {
                        border-radius: 4px;
                        border: 1px solid var(--formControlRootColor);
                        background: var(--whiteText);

                        .MuiInputBase-root {

                            .MuiInputBase-input,
                            .MuiOutlinedInput-notchedOutline {
                                border-radius: 4px;
                                border: none;
                                background: transparent;
                            }
                        }
                    }

                    .MuiAutocomplete-endAdornment {
                        right: 0px;

                        .MuiSvgIcon-root {
                            color: var(--darkPurple);
                            font-size: xx-large;
                        }
                    }

                    .Mui-focused {
                        color: var(--darkPurple);
                    }

                    .Mui-disabled {
                        opacity: 0.5;
                    }

                    .MuiFormLabel-root {
                        color: var(--formLabelRootColor);
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        vertical-align: middle;

                        .label-programmation {
                            display: flex;
                            align-items: center;
                            gap: 3px;

                            .MuiSvgIcon-root {
                                font-size: large;
                            }
                        }
                    }
                }
            }

            .form-group:last-of-type {
                margin-top: 72px;
                margin-bottom: 24px;
            }

            .list-btn {
                display: flex;
                justify-content: flex-start;

                button {
                    margin-right: 30px;
                    text-transform: capitalize;
                    width: auto;
                    background: var(--degradPurple);
                    border-radius: 50px;
                    padding: 10px 25px;
                }

                .btn-secondary {
                    background-color: transparent;
                    border: none;
                    background: var(--purple);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .infoFees {
                padding-bottom: 50px;
                color: var(--infoFeesColor);
                font-size: 14px;
                font-weight: 300;

                a {
                    color: var(--purple);
                    font-weight: 500;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-underline-offset: auto;
                    text-underline-position: from-font;
                    cursor: pointer;
                }
            }

            .icon-premium {
                margin-right: 10px;
            }
        }

        .meta-ban-info-container {
            margin-top: 24px;
            border-radius: 16px;
            border: 2px solid var(--redWhakup);
            padding: 20px 12px;

            .meta-ban-info-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;
                cursor: pointer;
                color: var(--redWhakup);
                font-size: 16px;
                font-weight: 600;
                list-style-type: none;

                p {
                    display: inline;
                    vertical-align: top;
                }

                .MuiSvgIcon-root {
                    position: relative;
                    right: -7px;
                    top: -1px;
                    font-size: 2.2em;
                }
            }

            .meta-ban-info-text {
                color: var(--textColor);
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                padding: 15px 0px;
            }

            .progress-bar-msgs-content {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;

                .progress-bar-msg-block {
                    display: flex;
                    flex-wrap: wrap;

                    .progress-bar-msg-title {
                        flex-grow: 1;
                        color: var(--textColor);
                        font-size: 12px;
                        font-weight: 500;
                    }

                    .progress-bar-msgs-block-details {
                        color: var(--purple);
                        font-size: 14px;
                        font-weight: 500;
                        flex-basis: 100%;
                        width: 213px;
                        margin-top: 5px;

                        display: flex;
                        flex-wrap: nowrap;

                        img {
                            display: inline-block;
                            width: 16px;
                            height: 38px;
                        }
                    }
                }
            }
        }
    }

    .aside-right {
        width: 487px;

        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        align-self: stretch;

        border-radius: 24px;
        border: 1px solid var(--lightPurpleText);
        background: var(--asideRightColor);

        .apercu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;

            h2 {
                color: var(--purple);
                font-size: 20px;
                font-weight: 600;
            }

            p {
                color: var(--greyText);
                font-size: 16px;
                font-weight: 500;

                a {
                    color: var(--darkPurple);
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-underline-offset: auto;
                    text-underline-position: from-font;
                    cursor: pointer;
                }
            }

        }

        .whatsapp-chat {
            position: static;
            margin: auto;
        }
    }
}