.step {
    border-radius: 6px;
    background: #FFF;
    margin-right:15px;
    flex: 4;
    padding:24px;

    .step-title {
        display: flex;
        flex-direction: row;
        
        .step-number {
            height:30px;
            width: 30px;
            border-radius: 50%;
            border-radius: 500px;
            border: 2px solid #311077;
            color:#311077;
            text-align: center;
            line-height: 26px;
        }

        .step-name {
            color: #2B272D;
            font-size: 18px;
            font-weight: 500;
            margin-left: 10px;
        }

    }

    .step-content {

        p {
            color: #2B272D;
            font-size: 15px;
            font-weight: 500;
            line-height: normal;
            margin-top:20px;
            height: 60px;
        }

        .step-link {
            color:white;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;

            .step-link-div {
                border-radius: 50px;
                border: 1px solid #311077;
                color:#311077;
                text-align: center;
                line-height: 32px;
                padding: 7px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .icon-plus {
                background-image:url("../../../assets/images/icons/icon-plus.svg");
                background-repeat: no-repeat;
                background-size: contain;
                color:white;
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }

            .icon-import {
                background-image:url("../../../assets/images/icons/icon-contact.svg");
                background-repeat: no-repeat;
                background-size: contain;
                color:white;
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }

            .icon-campaign {
                background-image:url("../../../assets/images/icons/icon-send.svg");
                background-repeat: no-repeat;
                background-size: contain;
                color:white;
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }

            svg {
                margin-right:10px;
            }
        }
    }
}


.inProgress {
    .step-title {
        .step-number {
            background: linear-gradient(90deg, rgba(189, 45, 105, 0.64) 0%, rgba(115, 30, 112, 0.72) 53%, rgba(49, 16, 119, 0.64) 100%);
            color:white;
            border: 0px solid #311077;
            line-height: 30px;
        }

        .step-name {
            font-weight: 600;
        }
    }

    .step-link {
        .step-link-div {
            background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
            line-height: 42px;
            border:none !important;

            span {
                color:white;
            }
        }
    }
}

.done {
    .step-title {
        .step-number {
            background-image: url("../../../assets/images/icons/icon-check-arround.svg");
            border:none !important;
          }

    }

    .step-link {
        .step-link-div {

            border: 1px solid var(--linear-test, rgba(189, 45, 105, 0.90)) !important;
            line-height: 42px;

            svg {
                color: var(--linear-test, rgba(189, 45, 105, 0.90));
            }

            span {
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%)) !important;
                background-clip: text !important;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
            }
        }
    }

}
