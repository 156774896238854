
.whatsapp-chat {
    display: flex;
    flex-direction: column;
    margin: auto;
    position: fixed;
    background:url('../../assets/images/phone.png');
    background-size:cover;
    width: 302px;
    height: 630px;
    padding-bottom: 35px;
    padding-right:20px;

    .chat-header {
        padding: 10px;
        font-weight: bold;
        height:95px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        margin-top:20px;
    
        p {
            color: white;
            margin: 0;
            font-weight: 500;
            line-height: 25px;
            margin-left:15px;
            text-align: center;
            margin-top:-15px;
        }
    }
    
    .chat-messages {
        flex-grow: 1;
        padding: 30px 15px 30px 30px;
        background-size: cover;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow-y: auto;

        @supports selector(::-webkit-scrollbar) {
            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgb(189, 189, 189);
                border-radius: 45px;
                border: 1px solid transparent;
            }
        }

        @supports not selector(::-webkit-scrollbar) {
            scrollbar-width: thin;
            scrollbar-color: rgb(189, 189, 189) transparent;
        }

        .language {
            p {
                color: #4866b2;
                margin: 0;
                font-weight: 500;
                line-height: 45px;
                margin-left:15px;
            }
        }

        .contentMessage {
            margin-bottom: 5px;
            border-radius: 10px;
            padding: 10px 15px;
            max-width: 90%;
            background: #ffffff;
            align-self: flex-start;
            max-width: 350px;
            word-break: break-all;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

            .title {
                font-weight: bold;
            }

            .image {
                .upload-img {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .video-container {
                max-width: 350px; 
                border-radius: 10px;
            }
              
            .styled-video {
                width: 100%;
                height: auto;
                border-radius: 8px;
            }
              
            .message {
                font-size: 14px;
                word-break: break-word;
            }

            .footer {
                font-size: 12px;
            }
        }

        .carrousel {
            margin-bottom:5px;
            
            .carrousel-item {
                background-color: white;
                border-radius: 10px;
                padding: 4px;

                img {
                    width: 100%;
                    max-height: 135px;
                    border-radius: 5px;
                    object-fit: cover;
                }

                .carrousel-content {
                    padding:0 5px;
                    font-size:15px;
                    line-height: 1.5;
                }

                .actions {
                    margin-top:8px;
                    .button {
                        border-top:1px solid #e9e9e9;
                        padding: 10px 0;
                        text-align: center;

                        a {
                            text-align: center;
                            color:#0089ad;
                            font-size:13px;
                            font-weight: 500;

                            svg {
                                margin-right:8px;
                            }
                        }
                    }
                }
            }
        }

        .button-list {
            display: flex;
            flex-direction: column;

            .button {
                padding: 10px 15px;
                border-radius: 5px;
                background: #ffffff;
                cursor: pointer;
                width: 100%;
                text-align: center;
                font-size: 14px;
                word-break: break-all;
                margin-bottom: 5px;

                &:first-child {
                    margin-right: 5px;
                }

                svg {
                    width: 12px;
                    margin-right: 5px;
                }

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
    

    
    .chat-input {
        display: flex;
        border-top: 1px solid #ddd;

        input {
            flex-grow: 1;
            padding: 10px;
            border: none;
            border-right: 1px solid #ddd;
        }

        button {
            padding: 10px;
            border: none;
            background: #09f;
            color: #fff;
        }
    }
    


}
