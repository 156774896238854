.navigation-account {
    display: flex;
    flex-direction: row;

    a {
        color: #8C888E;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        margin-left:30px;
        text-transform: uppercase;
        padding:6px 20px;
        font-weight: 500;
        font-size: 14px;

        &:hover {
            border-radius: 12px;
            background: linear-gradient(90deg, rgba(189, 45, 105, 0.72) 0%, rgba(115, 30, 112, 0.81) 53%, rgba(49, 16, 119, 0.72) 100%);
            color:white;
        }
    }

    .active {
        border-radius: 12px;
        background: linear-gradient(90deg, rgba(189, 45, 105, 0.72) 0%, rgba(115, 30, 112, 0.81) 53%, rgba(49, 16, 119, 0.72) 100%);
        color:white;
    }
    
}