.integration {



    .content {
        width: 95%;
        margin: 0 auto;
        margin-top:50px;

        .listItemIntegration {
            display: flex;
            //justify-content: space-between;
            flex: 12;

            .itemIntegration {
                flex: 4;
                max-width: 430px;
                background-color: white;
                border-radius: 16px;
                padding: 24px 36px;

                .logo {
                    img {
                        max-width: 200px;
                        height: auto;
                    }

                }

                .info {
                    margin-top:10px;

                    p {
                        color: rgba(43, 39, 45, 0.70);
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .button {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;

                    .btnConnect {
                        background-color: transparent;
                        border-radius: 50px;
                        border: 1.25px solid var(--linear-test, rgba(189, 45, 105, 0.90));
                        padding: 10px 20px;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-decoration: none;
                    }

                    .btnVideo {
                        background-color: transparent;
                        border: none;
                        padding: 10px 20px;
                        border-radius: 8px;
                        font-size: 16px;
                        font-weight: 500;
                        background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        cursor: pointer;

                        img {
                            margin-right: 10px;
                        }
                    }


                }

            }
        }


    }


}