.pricingSubscription {
    .priceList {
        margin-top: 20px;

        .priceListContent {
            display: flex;
            justify-content: space-between;
            flex:12;
            margin-top:30px;

            .priceListItem {
                margin-right: 30px;

                flex: 4;
 

                h3 {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: center;
                    border-bottom: 1px solid grey;
                    margin-top:0px;
                }

                .content {
                    margin-top: 20px;
                    text-align: center;
                    font-size: 13px;
                    min-height:43px;
                }

                .price {
                    margin-top: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--violet);
                    font-size: 30px;
                    font-weight:bold;
                }

                .infoSup {
                    font-size: 13px;
                    line-height: 25px;
                    text-align: center;
                    color: black;
                    min-height:55px;
                }

                ul {
                    margin-top: 20px;
                    list-style-type: none;
                    min-height:230px;

                    li {
                        font-size: 13px;
                        line-height: 25px;
                        text-align: left;
                        color: black;
                    }
                }

                .btn {
                    margin-top: 20px;
                    border:1px solid var(--violet);
                    background-color: white;
                    border-radius: 30px;
                    padding: 0 20px;
                    height: 35px;
                    font-size: 13px;
                    line-height: 25px;
                    color: var(--violet);
                    transition:0.3s;
                    cursor: pointer;
                    width: 100%;

                    &:hover {
                        background-color: var(--violet);
                        color: white;
                        transition:0.3s;
                    }
                }

                &:hover {
                    transition:0.3s;
                    background-color: var(--violet) !important;

                    h3 {
                        color: white;
                        transition:0.3s;
                    }

                    .content {
                        color: white;
                        transition:0.3s;
                    }

                    .price {
                        color: white;
                        transition:0.3s;
                    }

                    .infoSup {
                        color: white;
                        transition:0.3s;
                    }

                    ul {
                        color: white;
                        transition:0.3s;

                        li {
                            color: white;
                            transition:0.3s;
                        }
                    }
                }

            }
        }


    }
}