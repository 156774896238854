.notifPanel {
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.notifPanelTitleBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.notifTitle {
    color: var(--textColor);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mainNotifIcon {
    filter: brightness(0) saturate(100%) invert(30%) sepia(91%) saturate(4183%) hue-rotate(320deg) brightness(79%) contrast(83%);
}