.metaStatus {
    display: flex;
    height: 50px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.statusRoundIconMeta {
    width: 16px;
    height: 16px;
}

.metaStatusText {
    color: var(--textColor);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.greenMetaStatus {
    fill: var(--green);
}

.redMetaStatus {
    fill: var(--red);
}

.orangeMetaStatus {
    fill: var(--orange);
}

.unknownMetaStatus {
    fill: var(--grey);
}