.kpi-item {
    width: 300px;
    height: 192px;
    border-radius: 16px;
    border: 1px solid rgba(189, 45, 105, 0.90);
    background-color:#FEFEFE;
    padding: 15px;

    .stats {

        h3 {
            color: #2B272D;
            font-family: Poppins;
            font-size: 40px;
            font-weight: 300;
            letter-spacing: -1.6px;
            margin-left:12px;
        }

        p {
            color: #8E8E8E;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            margin-top:-10px;
        }

        .red {
            color:#E41919;
        }

        .green {
            color:#4BB475;
        }

        .lightgreen {
            color:#0924c0;
        }

        .orange {
            color:#F6932A;
        }

    }

    p {
        color: #2B272D;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        margin-left: 15px;
        margin-top: 35px;
    }

}