.conversation {
    flex: 73% 1;
    position: relative;
    display: flex;

    .chatBody {
        flex: 1 1;
        display: flex;
        border-right: 1px solid #dadada;
        -webkit-flex-direction: column;
        flex-direction: column;
        z-index: 1;
        position: relative;
    }

    .chatBackground {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: .05;
        z-index: 1;
        background: url(../../../assets/images/bg-chat-light.png) #e4dcd4;
    }

    .header {
        background: #ededed;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        height: 60px;
        padding: 10px;
        min-height: 60px;
        width: 100%;
        z-index: 20;
        justify-content: space-between;

        .infoUser {
            display: flex;

            .avatar {
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
    
            .contentInfo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                padding: 0 10px;
    
                .name {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: #000;
                }
    
                .status {
                    font-size: 0.8rem;
                    color: #000;
                }
            }

        }

        .action {
            display: flex;
            align-items: center;

            .unread-message {
                button {
                    color: var(--primary);
                    padding: 5px 10px;
                    border-radius: 22px;
                    font-size: 13px;
                    margin-right: 10px;
                    border:1px solid var(--primary);
                    font-weight:300;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    
                    &:hover {
                        background: var(--primary);
                        color: white;
                    }
                }
            }
        
            .blacklist {
                display: flex;
                margin-right:30px;

                p {
                    font-size: 0.8rem;
                    color: #000;
                    margin-right: 10px;
                }
            }
        }



        .sendTemplate {
            background-image: linear-gradient(to right, #0a7739, #098640, #099546, #08a54d, #09b553);
            color: white;
            padding: 5px 15px;
            border-radius: 22px;
            margin-left: 10px;
            cursor: pointer;
            font-size: 0.9rem;
            position: absolute;
            right: 15px;
        }
    }

    .conversationContent {
        flex: 1 1;
        position: relative;
        background: #e4dcd4;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 5% 2pc;
        scroll-behavior: smooth;
        z-index: 10;

        .groupMessage {
            display: flex;
            margin-bottom: 12px;
            position: relative;
            flex-direction: column;
            z-index: 10;

            .messageDay {
                width: 100%;
                margin-bottom:15px;

                p {
                    text-align: center;
                    color: #000;
                    font-size: 0.8rem;
                    font-weight: 500;
                    padding: 5px 0;
                    background: #696969;
                    border-radius: 5px;
                    margin: 0 auto;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    padding: 5px 15px;
                    color:white;
                }
            }

            .message {
                padding: 6px 7px 8px 9px;
                font-size: .85rem;
                color: #000;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                max-width: 65%;
                line-height: 20px;
                border-radius: 5px;
                white-space: pre-line;
                display: flex;
                margin-bottom: 12px;
                position: relative;

                .messageContent {
                    font-size: .85rem;
                    color: #000;
                    line-height: 20px;
                    white-space: pre-line;
                }

                .filter {
                    width: 65px;
                    display: inline-block;
                    height: 3px;
                    background: transparent;
                }

                .time {
                    position: absolute;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    right: 7px;
                    bottom: 3px;
                    color: rgba(0,0,0,.45);
                    font-size: .7rem;
                    font-weight: 500;
                }

                .nameTime {
                    bottom:24px;
                }
            }

            .typeImage {
                width: 40%;
                min-width: 300px;
                max-width: 300px;
                padding: 4px !important;
                display: flex;
                flex-direction: column;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .time {
                    color:white;
                }

                svg {
                    margin-left:3px;
                    color: white;
                }

                .check {
                    color: #0da9e5;
                }
            }

            .sender {
                background: #fff;
                -webkit-align-self: flex-start;
                align-self: flex-start;

                &::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 0;
                    left: -8px;
                    border-color: #fff #fff transparent transparent;
                    border-style: solid;
                    border-width: 6px;
                }

            }

            .receiver {
                background: #dbf8c6;
                -webkit-align-self: flex-end;
                align-self: flex-end;
                
                &::before {
                    right: -8px;
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 0;
                    border-color: #dbf8c6 transparent transparent #dbf8c6;
                    border-style: solid;
                    border-width: 6px;
                }

                svg {
                    margin-left:3px;
                    color: #b3b3b3;
                }

                .check {
                    color: #0da9e5;
                }

            }

            .notification {
                background-color: rgb(255, 64, 64);
                color: white;
                border-radius: 6px;
                text-align: center;
            }

        }
        
    }

    .chat__footer {
        background: #f0f0f0;
        position: relative;
        z-index: 10;

        /* Emoji Tray */
        .emojis__wrapper {
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 0;
            min-height: 0;
            transition: all 0.4s ease;
            background: inherit;

            .emojis__tabs {
                display: flex;
                align-items: center;
                height: 50px;

                .emojis__tab {
                    flex: 1;
                    padding: 10px 5px 10px;
                    text-align: center;
                    position: relative;

                    button {
                        border: none;
                        background-color: transparent;
                    }
                }

                .emojis__tab--active::after {
                    content: "";
                    position: absolute;
                    height: 4px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background: rgb(0, 150, 136);
                }
            

            }

            .emojis__tab-icon {
                color: rgba(0, 0, 0, 0.32);
            }
            
            .emojis__tab--active .emojis__tab-icon {
                color: rgba(0, 0, 0, 0.6);
            }
            
            .emojis__content {
                overflow-y: scroll;
                padding: 5px 20px;
                flex: 1;
            }
            
            .emojis__search {
                height: 40px;
                background: #E6E6E6;
                width: 100%;
                border-radius: 5px;
                padding: 5px 10px;
                color: rgb(74, 74, 74);
                font-size: 0.9rem;
            }
            
            .emojis__search::placeholder {
                color: #989898;
            }
            
            .emojis__label {
                margin-top: 15px;
                margin-bottom: 5px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.45);
                font-size: 0.85rem;
            }
            
            .emojis__grid {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 25px;
            }
            
            .emojis__emoji {
                margin-right: 3px;
                margin-top: 3px;
                cursor: pointer;
            }

        }

        .emojis__wrapper--active {
            height: 40vh;
            min-height: 350px;
            transition: all 0.4s ease;
        }

        .chat__input-wrapper {
            padding: 10px;
            height: auto;
            position: relative;
            display: flex;
            align-items: center;
    
            button {
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
    
            .pos-rel {
                position: relative;
            }
    
            .chat__input {
                background: #fff;
                color: #4a4a4a;
                padding: 10px 10px;
                border-radius: 6px;
                -webkit-flex: 1 1;
                flex: 1 1;
                min-height:40px;
                max-height: 155px;
                height: 40px;
                overflow-y: auto;
                border:none;
                line-height:20px;
            }
    
        }


    }

}

