.quill {
  width: 100%;
  min-height: 450px;
    
  .ql-container {
      height: 100%;
      min-height: 450px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
  }
  
  .ql-editor {
      min-height: 400px;
      
      img.emoji {
          display: inline;
          width: auto;
          height: auto;
          vertical-align: middle; /* Alignement vertical correct */
      }
  }

  .ql-variable {
      margin-left:15px;
      width: 150px !important;
  }
}

.ql-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.error .quill .ql-container {
  border-color: red;
}

.ql-toolbar:has(+ .error) {
  border-color: red !important;
}

.carrousel .quill {
  min-height: 100px;

  .ql-container {
      height: 100%;
      min-height: 100px;
  }
  
  .ql-editor {
      min-height: 80px;
  }
}