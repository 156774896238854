.template {
    margin: 0 auto;

    /* Liste template */
    .background-white-title {
        display: flex;
        justify-content: space-between;;

        h1 {
            color: #2B272D;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .actions {
            display: flex;
            align-items: center;

            .btn-add {
                border-radius: 50px;
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                color: white;
                border: none;
                padding: 10px 20px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
            }
        }
    }

    /* Create template */
    .container {
        display: flex;
        flex-direction: row;

        .content {
            flex: 9;
            margin-right:30px;
            max-width: 1020px;
            min-height:600px;

            .icon-premium {
                margin-right:10px;
            }

            .info-meta {
                border-radius: 6px;
                background: #BD2D69;
                text-align: center;
                padding: 5px 0px;
                
                a {
                    color:white;
                    text-decoration: none;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    img {
                        margin: 0 10px 0 0;
                    }

                    span {
                        text-decoration: underline;
                    }

                }
            }

            .title-page {
                border-radius: 16px;
                background: #FFF;
                padding: 20px;
                margin: 20px 0 30px 0;

                h1 {
                    color: #2B272D;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #8C888E;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .form-group {
                margin-top: 10px;
            }

            .editor {
                margin-top: 30px;

                .action-group {
                    background-color: #f3f9fc;
                    padding: 10px;

                    button {
                        background-color: #f3f9fc;
                        padding: 5px 10px;
                        border-radius: 5px;
                        margin-right: 10px;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        border:none;

                        &:hover {
                            background-color: #09b453;
                            color: white;
                        }
                    }

                    .counter {
                        color: #525252;
                        font-size: 14px;
                        margin-left: 10px;
                        line-height: 32px;
                        float: right;
                    }
                }

                .public-DraftEditor-content {
                    min-height: 200px;
                    padding: 10px;
                    border: 1px solid #e5e5e5;
                }

            }

            .info-form {
                margin-top:30px;

                .title {
                    font-size: 16px;
                    font-weight: 600;

                    span {
                        color: rgb(48, 48, 48);
                        background-color: #f3f9fc;
                        padding: 5px;
                        border-radius: 5px;
                        font-size:10px;
                        margin-left: 5px;
                    }
                }

            }

            .selectTitle {
                max-width: 210px;
            }

            .listMedia {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                .media {
                    width: 150px;
                    height: 150px;
                    border:2px solid #e6e6e6;
                    border-radius: 15px;
                    margin-right: 30px;
                    text-align: center;
                    cursor: pointer;

                    img {
                        width: 70px;
                        margin-top:30px;
                    }

                    p {
                        margin-top: 5px;
                        font-size: 12px;
                        font-weight:bold;
                        margin-top:10px;
                    }

                    &:hover {
                        background-color: #f3f9fc;
                    }

                    &.active {
                        background-color: #f3f9fc;
                    }
                }

                .active {
                    border:1px solid #09b051;
                }

            }

            .info-media {
                background-color:#f7f7f7;
                padding: 10px;
                margin-top:20px;

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom:15px;
                }

                .css-wb57ya-MuiFormControl-root-MuiTextField-root{
                    max-width: 350px;
                }

                input {
                    max-width: 350px;
                    padding: 10px;
                }

                .upload-img {
                    img {
                        max-width: 250px;
                    }
                }
            }

            .containerBtn {
                position: relative;

                .btnAction {
                    border-radius: 50px;
                    border: 1px solid rgba(189, 45, 105, 0.90);
                    background: rgba(254, 254, 254, 0.20);
                    padding: 10px 20px;
                    margin-top: 10px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    color: black;
    
                    &:hover {
                        background-color: #e6f2f7;
                    }

                    &[disabled] {
                        cursor: not-allowed;
                        background-color: #e6f2f7;
                        border-color: var(--grey);
                        opacity: 0.5;
                    }
                }
    
                .listBtn {
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    z-index: 100;
                    background-color: white;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
                    width: 250px;
                    overflow: hidden;
    
                    h3 {
                        padding: 10px 20px;
                        cursor: pointer;
                        transition: all 0.3s ease;
    
                        &:hover {
                            background-color: #f3f9fc;
                        }
                    }
    
                    hr {
                        margin: 0;
                        color:#ccd0d5;
                        margin: 0 5px;
                    }
    
                    .action {
                        padding: 10px 20px;
                        cursor: pointer;
                        transition: all 0.3s ease;
    
                        p {
                            margin: 0;
                            font-size:13px;
                        }
    
                        span {
                            color: #525252;
                            font-weight: 400;
                            font-size:11px;
                        }
    
                        &:hover {
                            background-color: #f3f9fc;
                        }
                    
                    }

                    .disabled {
                        cursor: not-allowed;
                        color: #ccc;
                    }
                }

            }

            .info-footer {
                margin: 0;
                font-size: 13px;
                font-weight: 600;
                color: #4b566b;
                line-height: 1.66;
                letter-spacing: 0.03333em;
            }

            .MuiButton-root {
                max-width: 220px;
                border-radius: 50px;
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                color:white;
            }
        }

        .aside-right {
            min-width: 350px;
            max-width: 350px;
        }
    }

    em-emoji-picker {
        position: absolute;
        z-index: 500;
    }

    #preview {
        display: none;
    }

    .emoji-mart-emoji {
        span {
            font-size: 10px !important;
        }
    }

    .formHasError {
        margin-top: 30px;
        color: red;
    }

    .creationWarning {
        margin-top: 30px;
        font-size: 12px;
        margin-left: 15px;
        color: var(--grey);
    }

    .btnContainer {
        display: flex;
        align-items: center;
        margin-top: 15px;
        gap: 15px;
        flex-wrap: wrap;

        .disabled {
            cursor: not-allowed;
            background-color: #e6f2f7;
            border-color: var(--grey);
            opacity: 0.5;
        }
    }

    .btn-draft {
        border: 1px solid var(--linear-test, rgba(189, 45, 105, 0.90));
        outline: none;
        appearance: none;
        padding: 0 25px;
        border-radius: 50px;
        cursor: pointer;
        text-align: center;
        height: 36px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        background: transparent;
        text-wrap: nowrap;

        span {
            background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            vertical-align: middle;
        }
    }
}