
.sidebar {
    min-width: 300px;
    flex: 27% 1;
    border-right: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;

    .unread {
        font-weight: 500 !important;
    }

    .header {
        display: flex;
        background-color: #ededed;
        align-items: center;
        height: 60px;
        padding: 10px;
        min-height: 60px;
        justify-content: space-between;

        .user {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 10px;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 10px;
            }

            span {
                font-weight: 600;
            }
        }
    }

    .searchWrapper {
        padding: 7px 10px;
        height: 50px;
        background: #f6f6f6;
        display: flex;

        svg {
            width: 17px;
            height: 17px;
            color: var(--primary);
            position: relative;
            left: 30px;
            top: 10px;
        }

        input {
            background: #fff;
            color: #4a4a4a;
            padding-left: 40px;
            border-radius: 18px;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .listContact {
        flex: 1 1;
        overflow-y: scroll;
        background: #f5f5f5;
        border-top: 1px solid #dadada;

        .contactWrapper.active {
            background-color: #ffffff;
        }

        .contactWrapper {
            height: 72px;
            padding: 10px 20px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            border-bottom: 1px solid #ebebeb;
            cursor: pointer;

            .avatar {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            .contentContact {
                overflow: hidden;
                -webkit-flex: 1 1;
                flex: 1 1;

                .topContent {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;        

                    .name {
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .time {
                        font-size: .7rem;
                        color: rgba(0,0,0,.45);
                    }
                }

                .bottomContent {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
            
                    .message {
                        color: #000;
                        font-size: 13px;
                        font-weight: 300;
                        margin-right: 3px;
                        overflow: hidden;
                        max-height:25px;
                    }

                    .badge {
                        display: inline-block;
                        color: #fff;
                        background-color: #06d755;
                        border-radius: 18px;
                        min-width: 18px;
                        height: 18px;
                        padding: 0 3px;
                        line-height: 18px;
                        vertical-align: middle;
                        text-align: center;
                        font-size: .75rem;
                        font-weight: 500;
                    }
                }
            }

        }

    }
    

}

