.progress-container {
    width: 100%;
    position: relative;
}

.progress-wrapper {
    position: relative;
    padding-top: 10px;
    white-space: nowrap;
}

.MuiLinearProgress-root {
    height: 5px !important;
    border-radius: 10px !important;
    background-color: var(--pbWhite) !important;
    width: 85% !important;
    display: inline-block !important;

    .MuiLinearProgress-bar {
        background-color: var(--pbPurple);
    }
}


.block-progress-max {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin-left: 8px;

    .progress-max {
        display: inline-block;
        margin: auto;
    }
}

.progress-min {
    width: fit-content;
}

.progress-value {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    color: var(--pbPurple);
}