

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 650px;
    min-width: 650px;
    border-radius: 16px !important;
}

.import-contact {

    .header-popup {
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;
    
        p.title {
            margin: 0;
            padding: 0;
            font-size: 20px;
            font-weight: 500;
            color: #000;
        }

        svg {
            cursor: pointer;
        }
    }

    .selectFile {
        min-height: 300px;
        display: flex;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
            font-size: 15px;
            font-weight: 500;
            color: #000;

            span {
                color: rgb(161, 70, 70);
                font-weight:bold;
            }
        }
    }

    .mappingFile {
        background-color: #F4F3F8;
        padding: 20px;
        border-radius: 16px;
        

        p {
            margin: 15px 0px;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: #2B272D;

            br {
                margin-bottom: 10px;
            }

            span {
                color: #2B272D;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .error {
            color: red;
            font-size: 14px;
            font-weight: 500;
        }

        .container-mapping {
            background-color: white;
            border-radius: 16px;
            padding: 20px;

            .addCustomField {
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
        
                input {
                    background-color:white;
                    height:50px;
                    border: 1px solid #ccc;
                    border-radius: 6px;
                    padding:10px;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    max-width: 230px;
                }
    
                button {
                    background: none;
                    border:1px solid #311077;
                    border-radius: 50px;
                    color:#311077;
                    font-size: 13px;
                    font-weight: 500;
                    padding: 12px 15px;
                    cursor: pointer;
                    transition:0.2s;
    
                    &:hover {
                        text-decoration: none;
                        color:#ffffff;
                        background: #311077;
                    }
                }
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                flex:12;
    
                label {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    margin-right: 15px;
                    flex:4;
                }
                
                select {
                    background-color:white;
                    height:40px;
                    width: 100%;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 10px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    flex:8;
                }
            }
        }

        .addBtn {
            margin-top:40px;
            border-radius: 50px;
            border: none;
            background: linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%);
            color:#ffffff;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            padding: 10px 24px;
            transition:0.2s;
            float:right;
        }

        .mappingGroup {
            label {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                margin-right: 15px;
                flex:4;
            }

            select {
                border-radius: 30px;
                background-color:white;
                height:100px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                flex:8;
            }
        }

    }

    .confirm-import {
        margin-top:30px;

        h2 {
            color: #2B272D;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }

        p {
            color: #2B272D;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            margin-top: 20px;

            span {
                color:#311077;
            }
        }
    }

    .btnClose {
        color: #311077;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-right: 15px;
    }
}

