.report-bug {
    width: 175px;
    height: 50px;
    background-color: #f07272;
    color: white;
    position: fixed;
    top: 45vh;
    right: -125px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.9) 0px 2px 10px 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    .icon {
        width: 50px;
        height: 50px;
        svg {
            width: 20px;
            height: 20px;
            margin: 15px;
        }
    }

    a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
            color: white;
        }
    }

    &:hover {
        right: 0;
        transition: 0.3s;
    }
}