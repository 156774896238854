.reset-password {
    display: flex;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    
    .login-media {
        width: 50%;
        height:100vh;

        img {
            width: 100%;
            height: 100%;
            object-fit:cover;
        }
    }

    .login-form {
        width: 50%;
        height:100vh;
        padding-top:10%;

        .logo-whakup {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100%;

            img {
                width: 200px;
            }
        }

        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100%;

            h1 {
                font-size: 2.5rem;
                font-weight: 500;
                color: #333;
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            width: 400px;
            margin: 0 auto;
            margin-top: 30px;

            .error-msg {
                color: red;
                font-size: 0.875rem;
                margin-bottom: 30px;
            }

            .success-msg {
                color: green;
                font-size: 0.875rem;
                margin-bottom: 30px;
            }

            .form-group {
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                justify-content: space-between;

                label {
                    font-size: 1rem;
                    font-weight: 500;
                    color: #333;
                    margin-bottom: 10px;
                }

                svg {
                    font-size:17px;
                }

                .form-check {
                    display: flex;
                    align-items: center;

                    label {
                        font-size: 0.875rem;
                        color: rgba(58, 53, 65, 0.6);

                    }

                    input {
                        margin-right: 0.5rem;
                        margin-top:-7px;
                        width: 15px;
                        height: 15px;
                    }

                }

                .no-account {
                    text-align: center;
                    margin-top: 20px;
                    font-size: 13px;
                    width: 100%s;
                }

                a {
                    color: var(--primary);
                    font-weight: 500;
                    font-size: 12px;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }

            }

            .btn-submit {
                background-color: var(--primary);
                width: 100%;
            }
        }

    }

}