.table {
    min-height: 400px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 24px;
    border-radius: 16px;
    border: none !important;

    .MuiDataGrid-filler {
        display: none;
        flex-grow: 0;
    }

    .MuiDataGrid-topContainer {
        border-radius: 12px !important;
        overflow: hidden;
        background: var(--degradPurple);
        margin-bottom: 6px !important;

        .Mui-checked {
            border-radius: 4px !important;
            color: var(--purple) !important;
            border: 1px solid var(--purple) !important;
        }

        .MuiDataGrid-columnHeaders {
            border-radius: 12px !important;
            color: var(--white);
            font-size: clamp(10px, 0.8vw, 16px);
            font-weight: 500;
            letter-spacing: 0.17px;
            text-transform: uppercase;
            border: none;
            background: transparent;
            height: 68px !important;
        }


        .MuiDataGrid-columnHeaders>div {
            padding: 0px 16px;

            .MuiDataGrid-columnHeader:hover,
            .MuiDataGrid-columnHeader {
                height: 68px !important;
                text-align: center;
                max-width: fit-content !important;

                .MuiDataGrid-columnHeaderDraggableContainer {
                    width: fit-content !important;
                    min-width: min-content !important;

                    .MuiDataGrid-menuIcon {
                        min-width: fit-content !important;
                        position: relative;
                        top: 32px;
                        height: min-content !important;

                        .MuiButtonBase-root {
                            color: var(--white) !important;
                            opacity: 1 !important;
                            visibility: visible !important;
                            padding: 0px 3px;

                            .MuiSvgIcon-root {
                                height: min-content !important;
                                font-size: clamp(10px, 0.8vw, large);
                            }
                        }
                    }

                    .MuiDataGrid-columnHeaderTitleContainer {
                        flex-direction: column-reverse;
                        align-items: center;
                        justify-content: center;
                        height: fit-content !important;
                        margin: auto !important;
                        min-width: fit-content !important;

                        .MuiDataGrid-columnHeaderTitle {
                            text-wrap: balance !important;
                            word-break: keep-all !important;
                        }

                        .MuiDataGrid-columnHeaderTitleContainerContent {
                            .header-premium {
                                color: var(--lightPurplePremium);

                                .icon-premium {
                                    margin-left: 4px;
                                }
                            }
                        }

                        .MuiDataGrid-iconButtonContainer {
                            width: auto;

                            .MuiButtonBase-root {
                                padding: 0%;

                                .MuiDataGrid-sortIcon {
                                    color: var(--white);
                                    opacity: 1 !important;
                                    visibility: visible !important;
                                    width: 14px;
                                    flex-shrink: 0;
                                }
                            }
                        }
                    }
                }

                .MuiDataGrid-columnSeparator,
                .MuiDataGrid-columnSeparator:hover {
                    color: var(--white);
                    position: relative;
                    margin-left: auto;

                    .MuiDataGrid-iconSeparator {
                        margin: auto;
                        width: 12px !important;
                    }
                }
            }

            .MuiDataGrid-columnHeader--last {
                padding: 0% !important;

                .MuiDataGrid-columnSeparator {
                    display: none !important;
                    visibility: hidden !important;

                    .MuiDataGrid-iconSeparator {
                        display: none;
                    }
                }
            }

            .MuiDataGrid-columnHeader[data-field="nbSend"],
            .MuiDataGrid-columnHeader[data-field="nbView"],
            .MuiDataGrid-columnHeader[data-field="nbFail"],
            .MuiDataGrid-columnHeader[data-field="nbDelivered"],
            .MuiDataGrid-columnHeader[data-field="nbClick"] {
                .MuiDataGrid-columnHeaderDraggableContainer {
                    width: 150% !important;
                    margin: auto;
                    margin-left: 6.5px;
                }

                .MuiDataGrid-columnSeparator {
                    margin: 0%;
                }
            }
        }
    }

    .MuiDataGrid-main {
        height: auto !important;
        overflow: visible !important;

        .MuiDataGrid-virtualScroller {
            overflow: visible !important;
            height: auto !important;
            max-height: none !important;

            .MuiDataGrid-virtualScrollerContent {
                height: auto !important;
                min-height: auto !important;
                max-height: none !important;

                .MuiDataGrid-virtualScrollerRenderZone {
                    transform: none !important;
                    position: relative !important;
                }
            }
        }
    }

    .MuiDataGrid-row {
        background-color: var(--whiteText) !important;
        border-radius: 12px !important;
        border: none !important;
        padding: 0px 16px !important;
        min-height: 65px !important;
        max-height: none !important;
        height: auto !important;
        --height: auto !important;
        margin-top: 6px !important;
        margin-bottom: 6px !important;

        .MuiDataGrid-cell {
            font-size: 14px;
            color: var(--textColor);
            border: none !important;
            height: auto !important;
            min-height: 65px !important;
            display: flex !important;
            align-items: center !important;
            text-overflow: ellipsis !important;
            white-space: nowrap !important;

            .not-premium {
                filter: blur(4px);
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
            }
        }

        .MuiDataGrid-cell--flex {
            .iconsActionsCampaign {
                color: var(--textColor);
                margin: auto 10px auto 13px;
            }
        }
    }

    .MuiDataGrid-footerContainer {
        border: none;
        margin-top: 62px;

        .custom-pagination {
            margin: auto;
            color: #2B272D;

            .custom-pagination-statetext {
                text-align: center;
                font-weight: 400;

                .custom-pagination-statetext-bold {
                    font-weight: 600;
                }
            }

            .MuiPagination-root {
                position: relative;
                width: fit-content;
                height: fit-content;
                background: transparent;
                box-shadow: none;
                margin-top: 7px;

                .MuiPagination-ul {
                    .MuiButtonBase-root {
                        font-family: Poppins !important;

                        .MuiSvgIcon-root {
                            font-size: 26px;
                        }
                    }

                    .MuiPaginationItem-previousNext {
                        background-color: #644996;
                        color: white;
                    }

                    .Mui-disabled {
                        background-color: transparent !important;
                        color: #644996 !important;
                    }
                }
            }
        }
    }

    .MuiCheckbox-root {
        width: 20px !important;
        height: 20px !important;
        color: transparent !important;
        border: 1px solid var(--midGrey) !important;
        border-radius: 4px !important;
        cursor: pointer !important;

        .MuiSvgIcon-fontSizeMedium {
            margin-left: 0% !important;
        }

    }

    .MuiDataGrid-cellCheckbox {
        .Mui-checked {
            border-radius: 4px !important;
            color: var(--purple) !important;
            border: 1px solid var(--purple) !important;
        }
    }
}



.MuiDataGrid-overlay,
.MuiDataGrid-overlayWrapperInner {
    border-radius: 12px !important;
    opacity: 1;
    background-color: var(--white);

    .MuiCircularProgress-root {
        color: var(--darkPurple) !important;
    }
}

.css-1xylxj1-MuiTypography-root-MuiLink-root {
    color: var(--textColor) !important;
    text-decoration: none !important;

    img {
        vertical-align: middle !important;
        margin-right: 4px !important;
    }
}

.MuiDataGrid-columnHeaderCheckbox {
    .MuiDataGrid-columnSeparator {
        display: none !important;
    }
}