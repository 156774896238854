:root {
    --primary: #4eaa72;
    --primary-hover: #4eaa72;
    --grey: #364152;
    --violet: #791E94;
    
    --border-radius: 30px;

    --z-index-header: 50;
    --z-index-nav: 50;
    --z-index-dropdown: 100;
    --z-index-modal: 150;
    --z-index-toast: 150;
    --z-index-tooltip: 100;
}