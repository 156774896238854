.kpi {
    flex:9;
    border-radius: 12px;
    background: #FFF;
    margin-right:15px;
    padding:15px;

    h2 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
    }

    .stats {
        max-height: 600px;
    }
}


@media screen and (max-width: 938px) {
    .kpi {
        margin-bottom:30px;
    }
}
