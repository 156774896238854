.integration {

    .connected {
        margin-top:30px;
        .content {
            h1 {
                font-size: 24px;
                color: #333;
                margin-bottom: 20px;
            }

            p {
                font-size: 16px;
                color: #333;
                margin-bottom: 20px;
            }
        }
    }

    .noconnected {
        margin-top:30px;

        .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
            color: var(--primary);
        }

        h1 {
            font-size: 24px;
            color: #333;
            margin-bottom: 20px;
            text-align: center;
        }

        .container-integration {
            display: flex;
            margin-top: 50px;

            .container-meta {
                flex: 2;
                .stepper {
                    max-width: 1000px;
                    margin: 0 auto;
                    margin-top:50px;
                }

                .content {
                    margin-top: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        color: var(--primary);
                        cursor: pointer;
                    }

                    .contentStepper {
                        max-width: 1000px;

                        .card {
                            margin-top:30px;
                            
                            img {
                                max-width: 100%;
                                object-fit: cover;
                                border-radius: 5px;
                                margin-bottom: 20px;
                                
                            }
                        }

                        p {
                            font-size: 16px;
                            color: #333;
                            margin-bottom: 20px;
                        }

                        ul {
                            margin-bottom: 20px;

                            li {
                                margin-right: 20px;
                                margin-top:15px;
                                font-size: 16px;
                                color: #333;
                            }
                        }

                    }

                    .footerStepper {
                        margin-top: 50px;
                        display: flex;
                        justify-content: space-between;

                        .disabled {
                            background-color: #ccc !important;
                            color: #fff !important;
                            padding: 10px 20px !important;
                            border-radius: 5px !important;
                            cursor: not-allowed !important;
                        }

                        .btnNext {
                            background-color: #09b151;
                            color: #fff;
                            padding: 10px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: all 0.3s;

                            &:hover {
                                background-color: #318a56;
                            }
                        }

                        .btnPrev {
                            background-color: #09b151;
                            color: #fff;
                            padding: 10px 20px;
                            border-radius: 5px;
                            cursor: pointer;
                            transition: all 0.3s;

                            &:hover {
                                background-color: #318a56;
                            }
                        }
                    }




                }
            }

            .tuto-loom {
                flex: 1;
                margin-top:50px;
            }

        }

    }

}