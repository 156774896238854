.stats-click {
    border-radius: 12px;
    background-color: white;
    padding: 20px;
    overflow-x: auto;

    h3 {
        color: #2B272D;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        word-wrap: break-word;

        th, td {
            text-align: left;
            padding: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        th {
            color: #2B272D;
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            text-transform: uppercase;
        }

        .nbClickTh {
            text-align: center;
        }

        td {
            font-size: 16px;
            font-weight: 400;
            color: #303030;
            font-family: 'Poppins', sans-serif;
        }

        .url {
            color: rgba(49, 16, 119, 0.42);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            word-break: break-all;
        }

        .nbClick {
            color: rgba(115, 30, 112, 0.90);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
        }
    }

    /* Responsive */
    @media (max-width: 768px) {
        table {
            font-size: 14px;
        }

        th, td {
            padding: 8px;
        }
    }
}
