.statusCampaign {
    font-size: clamp(9px, 0.729vw, 14px);
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    min-width: 80px !important;
    text-align: center;
    height: min-content !important;
    width: clamp(8px, 0.729vw, fit-content);
    text-wrap: balance !important;
    word-break: keep-all !important;
    line-height: normal !important;
    vertical-align: middle;
}

.grey {
    color: var(--tagGreyColor);
    background: var(--tagGreyColorBackground);
}

.blue {
    color: var(--blue);
    background: var(--tagBlueColorBackground);
}

.orange {
    color: var(--orange);
    background: var(--tagOrangeColorBackground);
}

.red {
    color: var(--red);
    background: var(--tagRedColorBackground);
}

.green {
    color: var(--green);
    background: var(--tagGreenColorBackground);
}