.one-campaign {
    max-width: 1600px;
    margin:0 auto;

    .backpage {
        display: flex;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        p {
            color: #2B272D;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;

            a {
                color: #2B272D;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                text-decoration: none;
                
                &:hover {
                    color: #2B272D;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                }
            }
        }
    }

    .title-campaign {
        color: #2B272D;
        font-size: 36px;
        font-weight: 500;
        margin-top:15px;
    }


    .nb-send-message {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin-top:15px;
    }

    .engagements {
        border-radius: 16px;
        background-color:#FEFEFE;
        padding: 20px;
        margin-top: 25px;

        .title {
            display: flex;
            justify-content: space-between;
            padding-left:15px;

            h2 {
                color: #2B272D;
                font-size: 24px;
                font-weight: 600;
            }

            button {
                border-radius: 50px;
                border: 1px solid #644996;
                background-color: white;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                padding: 10px 20px;
                cursor: pointer;
                color: #644996;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
                transition: all 0.3s ease;

                img {
                    margin-right: 10px;
                }

                &:hover {
                    background-color: #644996;
                    color: white;


                }
            }
        }

        .kpi-content {
            display: flex;
            flex:12;

            .kpi {
                display: flex;
                flex-direction: row;
                flex:9;
                gap: 35px;
                flex-wrap: wrap;


            }

            .kpi-right {
                flex:3;
                .kpi-graph {}

                .budget-meta {
                    max-width: 300px;
                    border-radius: 16px;
                    border: 1px solid rgba(189, 45, 105, 0.90);
                    background: linear-gradient(116deg, #201730 14.41%, #644996 97.94%), #FEFEFE;
                    padding: 10px 36px;
                    color:white;
                    margin: 0 auto;
                }

                .roi-estimated {
                    max-width: 300px;
                    border-radius: 16px;
                    border: 1px solid rgba(189, 45, 105, 0.90);
                    background: linear-gradient(116deg, #201730 14.41%, #644996 97.94%), #FEFEFE;
                    padding: 10px 36px;
                    margin: 0 auto;
                    margin-top:15px;
                    color:white;
                }
            }

        }

    }

    .content-bottom {
        display: flex;
        flex: 12;
        margin-top:50px;

        .content-left {
            flex: 9;

            .contact-information {
                margin-top:50px;
                border-radius: 12px;
                background-color: white;
                padding: 20px;

                .title {
                    display: flex;
                    justify-content: space-between;

                    h3 {
                        color: #2B272D;
                        font-family: Poppins;
                        font-size: 24px;
                        font-weight: 600;
                    }

                    .list-button {
                        display: flex;
                        gap: 30px;

                        button {
                            border-radius: 50px;
                            border: 1px solid #644996;
                            background-color: transparent;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 10px 20px;
                            cursor: pointer;
                            color: #644996;
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 500;
                            transition: all 0.3s ease;
            
                            img {
                                margin-right: 10px;
                            }
            
                            &:hover {
                                background-color: #644996;
                                color: white;
            
            
                            }
                        }
                    }
                }

                .list-contact {
                    margin-top: 30px;

                    .filter {
                        display: flex;
                        gap: 10px;

                        button {
                            border-radius: 12px 12px 0px 0px;
                            background: white;
                            border: none;
                            padding: 10px 12px;
                            justify-content: center;
                            align-items: center;
                            color: #2B272D;
                            font-family: Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                            cursor: pointer;
                            
                            span {
                                color: #954C8C;
                                font-family: Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: uppercase;
                            }

                            &:hover {
                                background-color: #dfdae9;
                                color: #644996;
                                font-family: Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                text-transform: uppercase;
    
                                span {
                                    color: #954C8C;
                                    font-family: Poppins;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    text-transform: uppercase;
                                }
                            }

                        }

                        .actif {
                            background-color: #dfdae9;
                            color: #644996;
                            font-family: Poppins;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;

                            span {
                                color: #954C8C;
                                font-family: Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: uppercase;
                            }
                        }
                    }

                }

                .table {
                    margin-top: 0px;
                }
            }
        }

        .content-right {
            flex: 3;
            margin-top:50px;
            .whatsapp-chat {
                position: unset !important;
            }
        }
    }

}