.faq {

    max-width: 1400px;
    margin: 0 auto;
    

    .help {
        margin-bottom: 20px;
        text-align: center;
        background-image: linear-gradient(to right, #0a7739, #098640, #099546, #08a54d, #09b553);

        h1 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 20px;
            color:white;
        }

        p {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 20px;
            color:white;
        }

        .searchBar {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            svg {
                width: 17px;
                height: 17px;
                color: var(--primary);
                position: relative;
                left: 30px;
            }

            input {
                width: 600px;
                height: 50px;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 0 10px 0 40px;
                font-size: 15px;
                font-weight: 400;
                outline: none;
            }

            button {
                width: 40px;
                height: 40px;
                border: none;
                border-radius: 5px;
                background-color: var(--primary);
                color: #fff;
                font-size: 15px;
                font-weight: 400;
                outline: none;
                cursor: pointer;
                margin-left: 10px;
            }
        }
        
    }

    .faq-content {
        display: flex;
        

        .faq-category {
            width: 300px;
            margin-right: 20px;

            ul {
                li {
                    list-style: none;
                    margin-bottom: 5px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #333;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border-radius: 8px;
                    padding: 10px 20px;
                    text-transform: uppercase;

                    svg {
                        width: 15px;
                        height: 15px;
                        margin-right: 10px;
                        color: var(--primary);
                    }


                }

                .active {
                    box-shadow: rgba(58, 53, 65, 0.42) 0px 4px 8px -4px;
                    background-image: linear-gradient(to right, #0a7739, #098640, #099546, #08a54d, #09b553);
                    color: #fff;

                    svg {
                        color: white;
                    }

                }
            }

            
        }

        .faq-list {
            width: calc(100% - 300px);

            .MuiTypography-body1 {
                font-size: 16px;
                font-weight: 600;
                color: #333;
                line-height: 1.5;
            }


            .content-detail {
                font-size: 15px;
                font-weight: 400;
                color: #333;
                line-height: 1.5;
                padding:0px 30px;

                a {
                    color: var(--primary);
                    text-decoration: none;
                    font-weight: 600;
                }
                
                ul {
                    margin-left: 20px;
                    margin-bottom: 20px;

                    li {
                        list-style: disc;
                        margin-bottom: 5px;
                        font-size: 15px;
                        font-weight: 400;
                        color: #333;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        border-radius: 8px;
                        padding: 10px 20px;

                        a {
                            color: var(--primary);
                            text-decoration: none;
                            font-weight: 600;
                        
                        }
                    }
                }

                p {
                    margin-bottom: 20px;
                }

                img {
                    max-width: 100%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            
        }
    }
}