.selectFile {
    background-color: #F4F3F8;
    padding: 20px;
    border-radius: 16px;

    p {
        color: #2B272D;
        text-align: center;
        font-size: 16px;
        font-weight: 400;

        span {
            color:#E41919;
        }
    }
}