

.containerButton {
    background-color:#f8fafb ;
    width: calc(100% - 40px);
    padding: 20px;
    overflow: hidden;
    display: flex;
    border:1px solid #dadde1;
    border-radius: 5px;
    flex-direction: row;

    .buttonContainer {
        width: 100%;
        margin:0 10px;

        p {
            margin: 0;
            font-size: 13px;
            font-weight: 600;
            color: #4b566b;
        }

        .MuiFormControl-root {
            background-color: white;
        }

    }
}

.deleteBtn {
    margin-left: 20px;
    cursor: pointer;
}


.buttonCallActionWebsite {
    display: flex;
    align-items: center;
    margin-top:15px;
}


.buttonCallActionOffer {
    display: flex;
    align-items: center;
    margin-top:15px;

    .containerButton {
        display: block;

        .info {
            margin:15px 0;

            p {
                margin: 0;
                font-size: 13px;
                font-weight: 400;
                color: #4b566b;
            }
        }
    }
}

.buttonCallActionPhone {
    display: flex;
    align-items: center;
    margin-top:15px;
}