.groups {
    flex:2.5;
    min-width: 200px;
    background-color: white;
    border-radius: 16px;

    .list-group {

        .one-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #f2f2f2;
            cursor: pointer;
            position: relative;

            &:hover {
                background-color: #e0dbeb;
            }

            .info-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 20px;

                .text-group {

                    .title-group {
                        font-weight: 500;
                        font-size: 1rem;
                    }

                    .count-contact {
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.43;
                        letter-spacing: 0.01071em;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }


            }

            .action-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);

                .icon-action {
                    display: flex;
                    width: 30px;
                    height: 30px;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            
            }

            &:first-child {
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }
        }

        .active {
            background-color: #e0dbeb;
        }

        .add-group {
            color: #311077;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            justify-content: center;
            padding: 30px 0;
            border-bottom: 0px solid #f2f2f2 !important;

            svg {
                margin-right: 20px;
            }

            &:hover {
                background-color: white !important;
            }
        }
    }
}