.trial-offer {
    width: 100%;
    height:30px;
    background-color: rgb(218, 33, 33);
    position: fixed;
    top: 0;
    z-index: 30;

    p {
        color: white;
        text-align: center;
        font-size: 14px;
        line-height: 30px;
    }
}