@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('assets/scss/variable.scss');
@import url('assets/scss/typo.scss');
@import url('assets/scss/form.scss');
@import url('assets/scss/table.scss');
@import url('assets/scss/quill.scss');

:root {
    // General colors
    --purple: #644996;
    --degradPurple: linear-gradient(116deg, #201730 14.41%, #644996 97.94%);
    --darkPurple: #311077;
    --redWhakup: #BD2D69;
    --textColor: #2B272D;
    --lightPurplePremium: #897792;
    --lightPurpleActive: #E7DBFF;

    --green: #4BB475;
    --red: #FF4D4F;
    --orange: #FFA940;
    --blue: #6294EE;
    --grey: #D9D9D9;
    --darkGrey: #8E8E8E;
    --midGrey: #B3B3B3;

    --background: #F5F1FF;
    --white: white;

    --blackRgba: rgba(0, 0, 0, 0.16);
    --blackRgba2: rgba(0, 0, 0, 0.45);
    --blackRgba3: rgba(0, 0, 0, .06);
    --blackRgba4: rgba(0, 0, 0, .2);
    --blackRgba5: rgba(0, 0, 0, 0.32);
    --blackRgba6: rgba(0, 0, 0, 0.6);
    --blackRgba7: rgba(0, 0, 0, 0.08);
    --blackRgba8: rgba(0, 0, 0, 0.25);
    --blackRgba9: rgba(0, 0, 0, 0.05);


    // Campaign hex colors
    --lightPurpleText: #E7DBFF;
    --whiteText: #FEFEFE;
    --greyText: #8C888E;

    // Campaign rgba colors
    --createCampaignContainerColor: rgba(231, 219, 255, 0.34);
    --formControlRootColor: rgba(49, 16, 119, 0.15);
    --formLabelRootColor: rgba(43, 39, 45, 0.80);
    --infoFeesColor: rgba(43, 39, 45, 0.70);
    --asideRightColor: rgba(231, 219, 255, 0.07);

    // Tags component colors
    --tagGreyColor: rgba(67, 48, 75, 0.70);
    --tagGreyColorBackground: #CEC3D3;
    --tagBlueColorBackground: rgba(98, 148, 238, 0.40);
    --tagOrangeColorBackground: rgba(246, 147, 42, 0.30);
    --tagRedColorBackground: rgba(228, 25, 25, 0.20);
    --tagGreenColorBackground: rgba(75, 180, 117, 0.40);

    // Progress bar component colors
    --pbPurple: #3F1D80;
    --pbWhite: #D3D3D3;
}

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    overflow-x: hidden;
    color: #212529;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    background: linear-gradient(0deg, #F5F1FF 0%, #F5F1FF 100%), #FFF;
}

.whakup {

    .whakup-container {
        display: flex;

        .whakup-content {
            width: 100%;

            .whakup-content-container {
                padding-top: 90px;
                padding-left: 130px;
                padding-right: 30px;
                margin-bottom: 50px;

                .isTrial {
                    padding-top: 30px;
                }

                .background-white {
                    border-radius: 14px;
                    background: #FFF;
                    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    padding: 20px;
                }
            }
        }

        .mobile-container {
            margin: 0 auto;
            margin-top: 30vh;
            padding: 0 30px;

            h1 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
                text-align: center;
            }

            p {
                font-size: 16px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

}

.disable-btn {
    opacity: 0.5;
}

@media screen and (max-width: 938px) {
    .whakup {
        .whakup-container {
            .whakup-content {
                .whakup-content-container {
                    padding-left: 130px;
                    padding-right: 20px;
                }
            }
        }
    }

}