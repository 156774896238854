header {
    min-width: 500px;
    max-width: 600px;
    height: 75px;
    background: #FFF;
    box-shadow: 0px 0px 8.1px 0px rgba(138, 168, 223, 0.50);
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0px 0px 24px 24px;
    position: fixed;
    z-index: 30;
    left:40%;
    padding-left:10px;
    padding-right:10px;

    .header-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        padding: 15px 0;

        .btn {
            height: 45px;
            padding: 0 25px;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;
            margin-right: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            border: 1px solid var(--linear-test, rgba(189, 45, 105, 0.90));
            line-height: 42px;

            span {
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &:hover {
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));

                span {
                    background: white;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .user {
            position: relative; /* Added relative positioning */
            display: flex;
            align-items: center;
            margin-right: 30px;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 10px;
            }

            p {
                color: #2B272D;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            svg {
                margin-left: 10px;
            }

            .user-nav {
                position: absolute;
                z-index: 300;
                background-color: white;
                width: 150px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 10px;
                left:5px;
                top: 40px;
                display: flex;
                flex-direction: column;

                a {
                    color: #364152;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 10px 10px;
                    cursor: pointer;
                    transition: 0.1s;
                    text-decoration: none;
                    border-radius: 10px;

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }

        .lang {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 5px;

            img {
                width: 30px;
                height: 20px;
                margin-right: 10px;
            }

            .lang-nav {
                position: absolute;
                z-index: 300;
                background-color: white;
                width: 60px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                padding: 10px;
                left: -15px;
                top: 30px;
                display: flex;
                flex-direction: column;

                div {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    cursor: pointer;
                    transition: 0.1s;
                    border-radius: 10px;

                    img {
                        width: 30px;
                        height: 20px;
                        margin-right: 10px;
                    }

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }

        .notification {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 15px;
            
        
            .notification-icon {
                position: relative;
                display: flex;
                cursor: pointer;
                align-items: center;
        
                img {
                    width: 20px;
                    height: auto;
                }
        
                .notif-badge {
                    position: absolute;
                    top: 5px;
                    right: 4px;
                    background-color: #B22A5E;
                    color: white;
                    font-size: 11px;
                    font-weight: bold;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: translate(50%, -50%);
                }
            }

            .notification-nav {
                position: absolute;
                z-index: 300;
                padding: 12px 10px;
                border-radius: 12px;
                background: #FFF;
                left: 0px;
                top: 50px;
                display: flex;
                flex-direction: column;
                width: max-content;
                min-width: 200px;

                h3 {
                    color: #2B272D;
                    font-size: 16px;
                    font-weight: 500;
                    margin-left:10px;
                    margin-bottom: 5px;
                }

                .notif-item {
                    display: flex;
                    cursor: pointer;
                    transition: 0.1s;
                    border-radius: 10px;
                    padding: 10px 10px;
    

                    img {
                        width: 15px;
                        margin-right: 5px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
        

    }
}
