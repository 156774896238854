.politique {

    .cgu {
        width: 1200px;
        margin: 0 auto;
        margin-top:60px;
    }

    h1 {
        font-size: 36px;
        font-weight: 600;
        color: #212529;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 24px;
        font-weight: 600;
        color: #212529;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: #212529;
        margin-bottom: 20px;
    }
}